import { render, staticRenderFns } from "./Tickets.vue?vue&type=template&id=3c84411b&scoped=true&"
import script from "./Tickets.vue?vue&type=script&lang=js&"
export * from "./Tickets.vue?vue&type=script&lang=js&"
import style0 from "./Tickets.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Tickets.vue?vue&type=style&index=1&id=3c84411b&lang=scss&scoped=true&"
import style2 from "./Tickets.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c84411b",
  null
  
)

export default component.exports