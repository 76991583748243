<template>
  <!-- modal -->
  <b-modal
    id="assign-agent-modal"
    ref="assign-agent-modal"
    title="Assign Ticket to TI Agent"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    :no-close-on-backdrop="true"
    @show="init"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        :state="agentState"
        label="Agent"
        label-for="agent-select"
        invalid-feedback="Select Agent"
      >
        <v-select
          id="agent-select"
          v-model="agent"
          :state="agentState"
          item-text="Name"
          item-value="Id"
          :options="agentList"
          required
          label="Name"
        />
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BModal, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import catalogServices from '@core/services/catalog/catalogService'
import ticketsServices from '@core/services/tickets/ticketsService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormGroup,
    vSelect,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    refreshHandler: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    agent: null,
    agentState: null,
    agentList: [],
    idTicket: 0,
  }),
  created() {
    this.$bus.$on('setParamsAssignAgentModal', param => {
      const { id } = param
      this.idTicket = id
    })
  },
  methods: {
    init() {
      catalogServices.getAllElementCatalog({
        table: 'Agents',
      }).then(response => {
        const { data } = response.data
        this.agentList = data
      })
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.agentState = valid
      return valid
    },
    resetModal() {
      this.agent = null
      this.agentState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.agent) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Agent no selected',
            text: 'You most select an IT Agent',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }
      // Push the name to submitted names
      const agentTicket = {
        idAgent: this.agent.Id,
        id: this.idTicket,
      }
      ticketsServices.assignAgentTicket(agentTicket).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            text: 'The ticket has been assigned',
            icon: 'InboxIcon',
            variant: 'success',
          },
        })
        this.refreshHandler()
        // Hide the modal manually
        this.$nextTick(() => {
          this.$refs['assign-agent-modal'].toggle('#toggle-btn')
        })
      }).catch(error => {
        this.toastErrorHandler(error)
      })
      // Hide the modal manually
    },
  },
}
</script>
