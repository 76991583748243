<template>
  <b-card no-body>
    <b-card-body>
      <!-- metting header -->
      <div class="meetup-header d-flex align-items-center mb-2">
        <div class="my-auto">
          <b-card-title class="mb-25">
            {{ ticket.title }}
          </b-card-title>
          <b-card-text class="mb-0">
            {{ ticket.description }}
          </b-card-text>
        </div>
      </div>
      <!--/ metting header -->

      <app-timeline>
        <app-timeline-item
          v-if="comments.length > 0"
          icon="UserIcon"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>Requested</h6>
            <small class="text-muted">{{ comments[0].SysDate }}</small>
          </div>
          <p>by {{ ticket.nameUser }}</p>
          <p
            v-if="ticket.file"
            class="my-0"
          >
            <!-- Preview Ticket -->
            <feather-icon
              :id="`preview-row-ticket-view-icon`"
              icon="EyeIcon"
              size="16"
              class="cursor-pointer mx-1"
              @click="getFile(ticket.file)"
            />
            <b-tooltip
              title="Review File"
              :target="`preview-row-ticket-view-icon`"
            />
            <span
              class="align-bottom"
            >Open file</span>
          </p>
        </app-timeline-item>

        <template v-for="(comment, index, key) in comments">
          <app-timeline-item
            v-if="comment.Type === 'Comment'"
            :key="index"
            :title="comment.Comment"
            :subtitle="`by ${comment.User}`"
            icon="MessageSquareIcon"
            :time="comment.SysDate"
            variant="success"
            index
          />
          <template
            v-else
          >
            <app-timeline-item
              v-if="comment.order > 5"
              :key="key"
              :title="comment.Status"
              icon="CheckCircleIcon"
              :time="comment.SysDate"
              variant="success"
            />
            <app-timeline-item
              v-else
              :key="index"
              title="Updated"
              :subtitle="`status:  ${comment.Status}`"
              icon="ClockIcon"
              :time="comment.SysDate"
              variant="success"
            />
            <app-timeline-item
              v-if="comment.order === 5"
              :key="key"
              title="Assigned"
              :subtitle="`to ${ticket.nameUserAssigned}`"
              icon="UserCheckIcon"
              :time="comment.SysDate"
              variant="success"
            />
          </template>
        </template>
      </app-timeline>
    </b-card-body>
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import {
  BCard, BCardBody, BCardTitle, BTooltip, BCardText,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ticketsServices from '@core/services/tickets/ticketsService'

export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BCardBody,
    BCardTitle,
    BTooltip,
    BCardText,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    comments: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    getFile(url) {
      if (!url) return
      const fullPath = ticketsServices.getUrlFile(url.replace('./', '/'))
      window.open(fullPath, '_blank')
    },
  },
}
</script>

<style>

</style>
