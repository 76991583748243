<template>
  <div>
    <b-modal
      id="history-ticket-modal"
      ref="history-ticket-modal"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      :title="`Ticket No. ${idTicket}`"
      @hidden="resetTicket"
    >
      <ticket-modal-ticket-timeline
        :ticket="ticket"
        :comments="comments"
      />
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ticketsServices from '@core/services/tickets/ticketsService'
import ticketModalTicketTimeline from '../ticketModalTicketTimeline.vue'

export default {
  components: {
    BModal,
    ticketModalTicketTimeline,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      ticket: {},
      comments: [],
      idTicket: 0,
    }
  },
  created() {
    this.resetTicket()
    this.$bus.$on('setParamsTicketTimeline', param => {
      const { id } = param
      this.idTicket = id
      this.loadTicket(id)
    })
  },
  methods: {
    loadTicket(id) {
      if (id === 0) return
      ticketsServices.getTicketById({ idTicket: id })
        .then(response => {
          const { data: { ticket } } = response.data
          this.ticket = ticket
        }).catch(error => {
          this.toastErrorHandler(error)
        })
      ticketsServices.getTicketLogStatusComment({ id })
        .then(response => {
          const { data } = response.data
          this.comments = data
        }).catch(error => {
          this.toastErrorHandler(error)
        })
    },
    resetTicket() {
      this.ticket = {
        idProject: 0,
        nameProject: '',
        idKind: 0,
        nameKind: '',
        idSubSubCat: 0,
        subSubCatName: '',
        idSubCat: 0,
        subCatName: '',
        idCat: 0,
        CatName: '',
        idPriority: 0,
        priority: '',
        title: '',
        description: '',
        fileLink: null,
        file: null,
        idTicket: 0,
      }
      this.comments = []
    },
  },
}
</script>
