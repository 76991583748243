import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class ProfileService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getTickets(...args) {
      // const [{ isExcel }] = args
      return axiosService.axiosIns.get(this.jwtConfig.getTickets, { params: args }, { responseType: 'blob' })
      // return axiosService.axiosIns.get(this.jwtConfig.getTickets, { params: args })
    }

    getTicketLogStatusComment({ id }) {
      return axiosService.axiosIns.get(`${this.jwtConfig.getTicketStatusComment}/${id}`)
    }

    commentTicket({ text, id }) {
      return axiosService.axiosIns.post(`${this.jwtConfig.getTicketStatusComment}/${id}`, { comment: text })
    }

    assignAgentTicket({ idAgent, id }) {
      return axiosService.axiosIns.put(`${this.jwtConfig.assignAgentTicket}/${id}`, { idAgent })
    }

    updateStatusTicket({
      idStatus, wkstDate, id, comment,
    }) {
      return axiosService.axiosIns.patch(`${this.jwtConfig.updateStatusTicket}/${id}`, { idStatus, wkstDate, comment })
    }

    updateTimeTicket({ time, id }) {
      return axiosService.axiosIns.patch(`${this.jwtConfig.updateTimeTicket}/${id}`, { time })
    }

    updateReportedByTicket({ name, id }) {
      return axiosService.axiosIns.patch(`${this.jwtConfig.updateReportedByTicket}/${id}`, { name })
    }

    updateIssuedByTicket({ name, id }) {
      return axiosService.axiosIns.patch(`${this.jwtConfig.updateIssuedByTicket}/${id}`, { name })
    }

    getTicketById(args) {
      return axiosService.axiosIns.get(`${this.jwtConfig.getTickets}/${args.idTicket}`)
    }

    getUrlFile(url) {
      return `${this.jwtConfig.baseUrlFiles}${url}`
    }

    createTicket({ args }) {
      return axiosService.axiosIns.post(this.jwtConfig.getTickets, args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
    }

    updateTicket({ id, args }) {
      return axiosService.axiosIns.put(`${this.jwtConfig.getTickets}/${id}`, args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
    }
}

function useProfileService() {
  const profileService = new ProfileService()

  return {
    profileService,
  }
}

const { profileService } = useProfileService()
export default profileService
