<template>
  <b-sidebar
    id="sidebar-add-new-ticket"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @hidden="resetTicket"
    @shown="init()"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ ticket === null ? 'Add' : 'Edit' }} Ticket
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- form -->
      <validation-observer
        v-slot="{ invalid }"
        ref="ticketValidation"
      >
        <!-- Body -->

        <b-form
          ref="ticketForm"
          class="p-2"
          @submit.prevent
        >

          <!-- Title -->
          <b-form-group
            label-for="title"
            label="Title"
          >
            <validation-provider
              #default="{ errors }"
              name="Title"
              vid="title"
              rules="required"
            >
              <b-form-input
                id="title"
                v-model="ticket.title"
                name="title"
                :state="errors.length > 0 ? false:null"
                placeholder="Title"
                autofocus
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Description -->
          <b-form-group
            label-for="description"
            label="Description"
          >
            <validation-provider
              #default="{ errors }"
              name="Description"
              vid="description"
              rules="required"
            >
              <b-form-input
                id="description"
                v-model="ticket.description"
                name="description"
                :state="errors.length > 0 ? false:null"
                placeholder="Description"
                autofocus
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Photo File -->
          <b-form-group
            label="Photo"
            label-for="Photo"
          >
            <b-input-group>
              <b-input-group-prepend
                is-text
                @click="getFile(ticket.fileLink)"
              >
                <feather-icon icon="DownloadIcon" />
              </b-input-group-prepend>
              <b-form-file
                v-model="imgFile"
                :placeholder="ticket.fileLink ? ticket.fileLink.replace('./docs/', '') : 'Choose a file or drop it here...'"
                accept="image/*"
                drop-placeholder="Drop file here..."
              />
            </b-input-group>
          </b-form-group>

          <!-- Project -->
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Project"
              rules="required"
            >
              <b-form-group
                label="Project"
                label-for="project"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  id="project"
                  v-model="ticket.project"
                  item-text="Name"
                  item-value="Id"
                  :options="catalogLists.Project"
                  label="Name"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>

          <!-- Category -->
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Category"
              rules="required"
            >
              <b-form-group
                label="Category"
                label-for="category"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  id="category"
                  v-model="ticket.category"
                  item-text="Name"
                  item-value="Id"
                  :options="catalogLists.Category"
                  label="Name"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>

          <!-- SubCategory -->
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="SubCategory"
              rules="required"
            >
              <b-form-group
                label="SubCategory"
                label-for="subCategory"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  id="subCategory"
                  v-model="ticket.subCategory"
                  item-text="Name"
                  item-value="Id"
                  :options="filteredSubCategory"
                  label="Name"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>

          <!-- SubSubCategory -->
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="SubSubCategory"
              rules="required"
            >
              <b-form-group
                label="SubSubCategory"
                label-for="subSubCategory"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  id="subSubCategory"
                  v-model="ticket.subSubCategory"
                  item-text="Name"
                  item-value="Id"
                  :options="filteredSubSubCategory"
                  label="Name"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>

          <!-- Kind -->
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Kind"
              rules="required"
            >
              <b-form-group
                label="Kind"
                label-for="kind"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  id="kind"
                  v-model="ticket.kind"
                  item-text="Name"
                  item-value="Id"
                  :options="catalogLists.Kind"
                  label="Name"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>

          <!-- Priority -->
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Priority"
              rules="required"
            >
              <b-form-group
                label="Priority"
                label-for="priority"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  id="priority"
                  v-model="ticket.priority"
                  item-text="Name"
                  item-value="Id"
                  :options="catalogLists.Priority"
                  label="Name"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>

          <!-- Status -->
          <b-form-group
            v-show="permissionAdmin"
            label="Status"
            label-for="status"
          >
            <v-select
              id="status"
              v-model="ticket.status"
              item-text="Name"
              item-value="Id"
              :options="catalogLists.Status"
              label="Name"
            />
          </b-form-group>

          <!-- Status Comment -->
          <b-form-group
            v-show="permissionAdmin"
            label-for="comment"
            label="Status comment"
          >
            <b-form-textarea
              id="comment"
              v-model="ticket.comment"
              name="comment"
              placeholder="Comment"
            />
          </b-form-group>

          <!-- Agent -->
          <b-form-group
            v-show="permissionAdmin"
            label="Agent"
            label-for="agent"
          >
            <v-select
              id="agent"
              v-model="ticket.agent"
              item-text="Name"
              item-value="Id"
              :options="catalogLists.Agents"
              label="Name"
            />
          </b-form-group>

          <!-- Reported By -->
          <b-form-group
            v-show="permissionAdmin"
            label="Reported By"
            label-for="reportedBy"
          >
            <v-select
              id="reportedBy"
              v-model="ticket.reportedBy"
              item-text="Name"
              item-value="Id"
              :options="catalogLists.UsersTicket"
              label="Name"
            />
          </b-form-group>

          <!-- Agent With Issue -->
          <b-form-group
            label="Agent With Issue"
            label-for="userIssued"
          >
            <v-select
              id="userIssued"
              v-model="ticket.userIssued"
              item-text="Name"
              item-value="Id"
              :options="catalogLists.UsersTicket"
              label="Name"
            />
          </b-form-group>

          <!-- Ticket Time -->
          <b-form-group
            v-show="permissionAdmin"
            label="Time"
            label-for="time-input-lbl"
            invalid-feedback="Time is required"
          >
            <b-input-group>
              <b-form-input
                id="time-input"
                v-model="ticket.time"
                type="text"
                placeholder="HH:mm:ss"
                autofocus
              />
              <b-input-group-append>
                <b-form-timepicker
                  v-model="ticket.time"
                  button-only
                  right
                  show-seconds
                  locale="en"
                  aria-controls="time-input"
                />
              </b-input-group-append>
            </b-input-group>

          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :key="buttonKey"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              :disabled="invalid"
              type="submit"
              @click.once="validationForm()"
            >
              {{ ticket.idTicket === 0 ? 'Add' : 'Edit' }}
            </b-button>

            <b-button
              ref="closeSideBarTickets"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormFile, BFormInvalidFeedback, BInputGroupPrepend, BInputGroup, BFormTextarea, BFormTimepicker, BInputGroupAppend,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import catalogServices from '@core/services/catalog/catalogService'
import ticketsServices from '@core/services/tickets/ticketsService'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BFormInvalidFeedback,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea,
    BFormTimepicker,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  props: {
    refreshHandler: {
      type: Function,
      required: true,
    },
    idTicket: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      imgFile: [],
      ticket: {
        idTicket: 0,
        title: '',
        description: '',
        file: '',
        fileLink: '',
        user: '',
        creationDate: '',
        userAssigned: '',
        project: null, // most be: { Id: Number, Name: String }
        kind: null, // most be: { Id: Number, Name: String }
        subSubCategory: null, // most be: { Id: Number, Name: String }
        subCategory: null, // most be: { Id: Number, Name: String }
        category: null, // most be: { Id: Number, Name: String }
        priority: null, // most be: { Id: Number, Name: String }
        status: null, // most be: { Id: Number, Name: String }
        agent: null,
        comment: null,
        reportedBy: null,
        userIssued: null,
        time: null,
      },
      catalogLists: {
        Priority: [], // most be Array of: { Id: Number, Name: String }
        Project: [], // most be Array of: { Id: Number, Name: String }
        SubCategory: [], // most be Array of: { Id: Number, Name: String }
        SubSubCategory: [], // most be Array of: { Id: Number, Name: String }
        Category: [], // most be Array of: { Id: Number, Name: String }
        Kind: [], // most be Array of: { Id: Number, Name: String }
        Status: [],
        Agents: [],
        UsersTicket: [],
      },
      buttonKey: 1,
      // validation rules
      required,
      permissionAdmin: false,
    }
  },
  computed: {
    filteredSubCategory() {
      const subCategoryList = this.catalogLists.SubCategory
      if (!this.ticket.category) return subCategoryList
      return subCategoryList.filter(item => item.IdCategory === this.ticket.category.Id)
    },
    filteredSubSubCategory() {
      const subSubCategory = this.catalogLists.SubSubCategory
      if (!this.ticket.subCategory) return subSubCategory
      return subSubCategory.filter(item => item.IdSubCategory === this.ticket.subCategory.Id)
    },
  },
  watch: {
    imgFile(file) {
      if (!file) return
      this.ticket.fileLink = file.name
      this.ticket.file = file
    },
  },
  methods: {
    init() {
      this.buttonKey += 1
      this.getCatalogs()
      this.loadTicket()
      this.getPermissions()
    },
    async getCatalogs() {
      const listCatalogs = Object.keys(this.catalogLists)
      listCatalogs.forEach(catalog => {
        this.getSpecificCatalog(catalog)
      })
    },
    getPermissions() {
      const profile = this.$store.getters['user-auth/getStaffProfile']
      const user = this.$store.getters['user-auth/getUser']
      if (profile === 1) this.permissionAdmin = true
      if (profile === 3) this.ticket.reportedBy = { Id: user.id }
    },
    loadTicket() {
      if (this.idTicket === 0) return
      ticketsServices.getTicketById({ idTicket: this.idTicket })
        .then(response => {
          const { data: { ticket } } = response.data
          this.orderTicket(ticket)
        }).catch(error => {
          this.toastErrorHandler(error)
        })
    },
    orderTicket(val) {
      if (val === undefined) return

      const {
        idProject,
        nameProject,
        idKind,
        nameKind,
        idSubSubCat,
        subSubCatName,
        idSubCat,
        subCatName,
        idCat,
        CatName,
        idPriority,
        priority,
        idAssigned,
        nameUserAssigned,
        // reportedBy,
        // agentWithIssue,
        idReportedBy,
        nameReportedBy,
        idAgentWithIssue,
        nameAgentWithIssue,
        statusId,
        status,
        comment,
        title,
        description,
        file,
        idTicket,
        time,
      } = val

      this.ticket = {
        project: { Id: idProject, Name: nameProject },
        kind: { Id: idKind, Name: nameKind },
        subSubCategory: { Id: idSubSubCat, Name: subSubCatName },
        subCategory: { Id: idSubCat, Name: subCatName },
        category: { Id: idCat, Name: CatName },
        priority: { Id: idPriority, Name: priority },
        agent: idAssigned ? { Id: idAssigned, Name: nameUserAssigned } : null,
        reportedBy: { Id: idReportedBy, Name: nameReportedBy },
        userIssued: { Id: idAgentWithIssue, Name: nameAgentWithIssue },
        status: statusId ? { Id: statusId, Name: status } : null,
        comment,
        title,
        description,
        fileLink: file,
        file: null,
        idTicket,
        time,
      }
      console.log(this.ticket)
    },
    /* eslint-disable no-param-reassign */
    validationForm() {
      this.$refs.ticketValidation.validate().then(success => {
        if (success) {
          const formData = new FormData()
          if (this.ticket.file) {
            formData.append('files', this.ticket.file)
          }
          const objTicket = {
            title: this.ticket.title,
            description: this.ticket.description,
            idKind: this.ticket.kind.Id,
            idProject: this.ticket.project.Id,
            idSubSubcategory: this.ticket.subSubCategory.Id,
            idPriority: this.ticket.priority.Id,
            wkstDate: moment().format('YYYY-MM-DD HH:mm:ss'),

            idStatus: this.ticket.status?.Id ?? null,
            idAgent: this.ticket.agent?.Id ?? null,
            comment: this.ticket.comment ?? null,
            reportedBy: this.ticket?.reportedBy?.Id ?? null,
            userIssued: this.ticket?.userIssued.Id ?? null,
            time: this.ticket.time ?? null,
          }
          formData.append('ticket', JSON.stringify(objTicket))
          ticketsServices[this.ticket.idTicket > 0 ? 'updateTicket' : 'createTicket']({ args: formData, id: this.ticket.idTicket }).then(() => {
            this.$refs.closeSideBarTickets.click()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                text: `Your ticket has been ${this.ticket.idTicket > 0 ? 'updated' : 'created'}`,
                icon: 'InboxIcon',
                variant: 'success',
              },
            })
            this.refreshHandler()
            this.resetTicket()
          }).catch(error => {
            this.buttonKey += 1
            this.toastErrorHandler(error)
          })
        }
      })
    },
    resetTicket() {
      this.ticket = {
        idTicket: 0,
        title: '',
        description: '',
        file: '',
        fileLink: '',
        user: '',
        creationDate: '',
        userAssigned: '',
        project: null, // most be: { Id: Number, Name: String }
        kind: null, // most be: { Id: Number, Name: String }
        subSubCategory: null, // most be: { Id: Number, Name: String }
        subCategory: null, // most be: { Id: Number, Name: String }
        category: null, // most be: { Id: Number, Name: String }
        priority: null, // most be: { Id: Number, Name: String }
        status: '',
      }
      this.imgFile = []
      this.resetFormTicket()
    },
    resetFormTicket() {
      this.$refs.ticketValidation.reset()
    },
    async getSpecificCatalog(catalog) {
      return catalogServices.getAllElementCatalog({
        table: catalog,
      }).then(response => {
        const { data } = response.data
        this.catalogLists[catalog] = data
      }).catch(error => {
        this.toastErrorHandler(error)
      })
    },
    /* eslint-enable no-param-reassign */
    getFile(url) {
      if (!url) return
      const fullPath = ticketsServices.getUrlFile(url.replace('./', '/'))
      window.open(fullPath, '_blank')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
