<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :options="PageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            v-if="permission.btnAddTicket"
            v-b-toggle.sidebar-add-new-ticket
            variant="primary"
            @click="Create"
          >
            Add Ticket
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search Title"
              @input="handleSearch"
            />
            <v-select
              v-model="statusFilter"
              :options="StatusOptions"
              class="invoice-filter-select"
              :clearable="false"
              placeholder="Select Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>

            </v-select>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="gradient-success"
            @click="getTicketExcel"
          >
            <feather-icon
              icon="ExternalLinkIcon"
              class="mr-50"
            />
            <span class="align-middle">Export</span>
          </b-button>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="tableTickets"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      style="position: relative !important;"
      @sort-changed="onSortChange"
    >
      <!-- Column: AccountNumber -->
      <template #cell(idTicket)="data">
        <b-link
          class="font-weight-bold"
        >
          #{{ data.value }}
        </b-link>
      </template>

      <!-- Column: Title -->
      <template #cell(title)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.value }}
          </span>
          <small class="text-muted">{{ data.item.description || '' }}</small>
        </b-media>
      </template>

      <!-- Column: Kind -->
      <template #cell(nameKind)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: User Creator -->
      <template #cell(nameUser)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: User Reporter -->
      <template #cell(reportedBy)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: User Reporter -->
      <template #cell(agentWithIssue)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Creation Date -->
      <template #cell(creationDate)="data">
        <span class="text-nowrap">
          {{ data.value | date-format }}
        </span>
      </template>

      <!-- Column: Assigned Agent -->
      <template #cell(nameUserAssigned)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Project Name -->
      <template #cell(nameProject)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: SubSub Category  -->
      <template #cell(subSubCatName)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Priority -->
      <template #cell(priority)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Status -->
      <template #cell(time)="data">
        <span class="text-nowrap">
          {{ data.value || '0' }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(Actions)="data">
        <div class="text-nowrap">

          <!-- Preview Ticket -->
          <feather-icon
            :id="`preview-row-${data.item.idTicket}-view-icon`"
            icon="EyeIcon"
            size="16"
            class="cursor-pointer ml-1"
            @click="viewTimeline(data.item.idTicket)"
          />
          <b-tooltip
            title="Preview Ticket"
            :target="`preview-row-${data.item.idTicket}-view-icon`"
          />

          <!-- Edit Ticket -->
          <feather-icon
            v-if="(permission.btnAddTicket && data.item.idStatus === 1) || permission.btnEditTicket"
            :id="`preview-row-${data.item.idTicket}-edit-icon`"
            v-b-toggle.sidebar-add-new-ticket
            icon="Edit3Icon"
            size="16"
            class="cursor-pointer ml-1"
            @click="editTicket(data.item.idTicket)"
          />
          <b-tooltip
            title="Add Ticket"
            :target="`preview-row-${data.item.idTicket}-edit-icon`"
          />

          <b-dropdown
            variant="link"
            no-caret
            toggle-class="NewPaddingDropdown"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <!-- Add Comment -->
            <b-dropdown-item
              v-if="data.item.statusOrder < 6"
              @click="addComment(data.item.idTicket)"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Add Comment</span>
            </b-dropdown-item>

            <!-- Update Status -->
            <b-dropdown-item
              v-if="permission.btnUpdateStatus && data.item.statusOrder < 6"
              @click="updateStatusTicket(data.item.idTicket, data.item.statusOrder)"
            >
              <feather-icon icon="ChevronsUpIcon" />
              <span class="align-middle ml-50">Update Status</span>
            </b-dropdown-item>

            <!--  Assign Agent -->
            <b-dropdown-item
              v-if="permission.btnAssignTicket && !data.item.nameUserAssigned"
              @click="assignTicket(data.item.idTicket)"
            >
              <feather-icon icon="UserCheckIcon" />
              <span class="align-middle ml-50">Assign Agent</span>
            </b-dropdown-item>

            <!--  Time of Ticket -->
            <b-dropdown-item
              @click="updateTimeTicket(data.item.idTicket)"
            >
              <feather-icon icon="ClockIcon" />
              <span class="align-middle ml-50">Time of Ticket</span>
            </b-dropdown-item>

            <!--  setReportedByTicket Ticket -->
            <b-dropdown-item
              @click="setReportedByTicket(data.item.idTicket)"
            >
              <feather-icon icon="UserPlusIcon" />
              <span class="align-middle ml-50">Set Reported By</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="setIssuedByTicket(data.item.idTicket)"
            >
              <feather-icon icon="UserPlusIcon" />
              <span class="align-middle ml-50">Set Agent with the issue</span>
            </b-dropdown-item>
          </b-dropdown>

        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ FullCountEntries > perPage ? perPage : FullCountEntries }} of {{ FullCountEntries }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            :current-page="currentPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="handleChangePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <sidebar-add-new-ticket
      :id-ticket="idTicket ? idTicket : 0"
      :refresh-handler="getTickets"
    />
    <history-ticket-modal />
    <add-comment-modal
      :refresh-handler="getTickets"
    />
    <assign-agent-modal
      :refresh-handler="getTickets"
    />

    <update-status-modal
      :refresh-handler="getTickets"
    />

    <update-time-modal
      :refresh-handler="getTickets"
    />

    <set-reported-by-modal
      :refresh-handler="getTickets"
    />

    <agent-with-issue-modal
      :refresh-handler="getTickets"
    />
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BPagination, BTable, BLink, VBToggle, BTooltip, BDropdown, BDropdownItem, BMedia,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import ticketsService from '@core/services/tickets/ticketsService'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import FileSaver from 'file-saver'
import SidebarAddNewTicket from './sidebar/SidebarAddNewTicket.vue'
import HistoryTicketModal from './modal/historyTicketModal.vue'
import AddCommentModal from './modal/addCommentModal.vue'
import AssignAgentModal from './modal/assignAgentModal.vue'
import UpdateStatusModal from './modal/updateStatusModal.vue'
import UpdateTimeModal from './modal/updateTimeModal.vue'
import SetReportedByModal from './modal/setReportedByModal.vue'
import agentWithIssueModal from './modal/agentWithIssueModal.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BPagination,
    vSelect,
    BTable,
    BLink,
    BTooltip,
    SidebarAddNewTicket,
    HistoryTicketModal,
    AddCommentModal,
    AssignAgentModal,
    UpdateStatusModal,
    UpdateTimeModal,
    SetReportedByModal,
    agentWithIssueModal,
    BDropdown,
    BDropdownItem,
    BMedia,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'idTicket', label: '#', sortable: true },
        { key: 'title', label: 'Title', sortable: true },
        // { key: 'nameKind', label: 'Kind', sortable: true },
        // { key: 'nameUser', label: 'Created By', sortable: true },
        { key: 'reportedBy', label: 'Reported By', sortable: true },
        { key: 'agentWithIssue', label: 'Agent with Issue', sortable: true },
        { key: 'creationDate', label: 'Creation Date', sortable: true },
        { key: 'nameUserAssigned', label: 'Assigned', sortable: true },
        { key: 'nameProject', label: 'Project', sortable: true },
        { key: 'subSubCatName', label: 'SubSub Category', sortable: true },
        { key: 'priority', label: 'Priority', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'time', label: 'Time of Ticket', sortable: true },
        { key: 'Actions', sortable: false }],
      items: [],
      TypeStaffValue: [],
      TypeofStaff: [],
      type: '',
      perPage: 10,
      PageOptions: [10, 25, 50, 100],
      StatusOptions: ['All', 'Open', 'Closed'],
      statusFilter: 'Open',
      actualpage: 1,
      totalRows: 0,
      currentPage: 1,
      idAssigned: 0, // agent
      idUser: 0, // ticket creator
      idUserProject: 0, // consultant
      FullCountEntries: 0,
      sortBy: 'idTicket',
      sortDesc: true,
      sortDirection: 'desc',
      isExcel: false,
      searchTerm: '',
      avatarText,
      ticket: {},
      idTicket: 0,
      permission: {
        btnAddTicket: true,
        btnAssignTicket: true,
        btnUpdateStatus: true,
        btnEditTicket: false,
      },
    }
  },
  watch: {
    perPage() {
      this.handleSearch()
    },
    TypeStaffValue(val) {
      if (val !== null) {
        this.type = val.Id
      } else {
        this.type = ''
      }
      this.getTickets()
    },
    statusFilter() {
      this.handleSearch()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    Create() {
      this.idTicket = null
    },
    viewTimeline(id) {
      this.$bus.$emit('setParamsTicketTimeline', { id })
      this.$root.$emit('bv::show::modal', 'history-ticket-modal')
    },
    addComment(id) {
      this.$bus.$emit('setParamsAddCommentModal', { id })
      this.$root.$emit('bv::show::modal', 'add-comment-modal')
    },
    assignTicket(id) {
      this.$bus.$emit('setParamsAssignAgentModal', { id })
      this.$root.$emit('bv::show::modal', 'assign-agent-modal')
    },
    updateStatusTicket(id, idStatusOrder) {
      this.$bus.$emit('setParamsUpdateStatusModal', { id, idStatusOrder })
      this.$root.$emit('bv::show::modal', 'update-status-modal')
    },
    updateTimeTicket(id) {
      this.$bus.$emit('setParamsUpdateTimeModal', { id })
      this.$root.$emit('bv::show::modal', 'update-time-modal')
    },
    setReportedByTicket(id) {
      this.$bus.$emit('setParamsReportedByTicketModal', { id })
      this.$root.$emit('bv::show::modal', 'update-reported-modal')
    },
    setIssuedByTicket(id) {
      this.$bus.$emit('setParamsIssuedByTicketModal', { id })
      this.$root.$emit('bv::show::modal', 'update-issued-modal')
    },
    editTicket(idTicket) {
      this.idTicket = idTicket
    },
    init() {
      this.getPermissions()
      this.getTickets()
    },
    getPermissions() {
      const user = this.$store.getters['user-auth/getUser']
      const profile = this.$store.getters['user-auth/getStaffProfile']
      switch (profile) {
        case 1:
          this.permission.btnEditTicket = true
          this.idUserProject = user.id
          break
        case 2:
          this.permission.btnAddTicket = false
          this.permission.btnAssignTicket = false
          this.idAssigned = user.id
          break
        case 3:
          this.permission.btnAssignTicket = false
          this.permission.btnUpdateStatus = false
          this.idUser = user.id
          break
        default:
          break
      }
    },
    getTicketExcel() {
      this.isExcel = true
      this.getTickets()
    },
    getTickets() {
      this.idTicket = 0
      ticketsService.getTickets({
        page: this.actualpage,
        name: this.searchTerm,
        rows: this.perPage,
        orderBy: this.sortBy,
        sortBy: this.sortDirection,
        idAssigned: this.idAssigned,
        idUser: this.idUser,
        idPriority: 0,
        idStatus: this.statusFilter,
        isExcel: this.isExcel,
        idUserProject: this.idUserProject,
      }).then(response => {
        if (this.isExcel) {
          const reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onload = () => {
            this.fileUrl = reader.result
          }
          // Assuming that the base64-encoded .xlsx file is stored in a variable called "base64data"
          FileSaver.saveAs(response.data, `Tickets ${moment().format('MM/DD/YYYY')}.xlsx`)
          this.isExcel = false
        } else {
          const { data } = response.data
          this.items = data
          this.FullCountEntries = !data ? 0 : data[0].full_count
          this.numPages = !data ? 0 : data[0].pages
          this.totalRows = this.FullCountEntries
          this.currentPage = this.actualpage
        }
      })
    },
    handleSearch(searching = '') {
      this.searchTerm = searching
      this.actualpage = 1
      this.totalRows = 1
      this.getTickets()
    },
    handleChangePage(page) {
      this.actualpage = page
      this.getTickets()
    },
    onSortChange(params) {
      this.sortBy = params.sortBy
      this.sortDesc = params.sortDesc
      const direction = params.sortDesc === true ? 'desc' : 'asc'
      this.sortDirection = direction
      this.getTickets()
    },
  },
}
</script>

<style>
.per-page-selector {
  width: 90px;
}
.vs__selected-options{
    height: 30px !important;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.b-avatar .b-avatar-text{
    font-size: 10px !important;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
