<template>
  <!-- modal -->
  <b-modal
    id="update-reported-modal"
    ref="update-reported-modal"
    title="Set Reporter User"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    :no-close-on-backdrop="true"
    @show="init"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <validation-observer
      ref="reportedValidation"
    >
      <b-form
        ref="form"
        @submit.prevent
      >
        <!-- Status -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Reported By"
            rules="required"
          >
            <b-form-group
              label="Reported By"
              label-for="reportedBy"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                id="reportedBy"
                v-model="reportedBy"
                item-text="Name"
                item-value="Id"
                :options="userList"
                label="Name"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormGroup, BModal, VBModal, BForm, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import catalogServices from '@core/services/catalog/catalogService'
import ticketsServices from '@core/services/tickets/ticketsService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormGroup,
    vSelect,
    BModal,
    BForm,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    refreshHandler: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    idTicket: 0,
    reportedBy: null,
    userList: [],
  }),
  created() {
    this.$bus.$on('setParamsReportedByTicketModal', param => {
      const { id } = param
      this.idTicket = id
    })
  },
  methods: {
    init() {
      catalogServices.getAllElementCatalog({
        table: 'UsersTicket',
      }).then(response => {
        const { data } = response.data
        this.userList = data
      })
      // this.resetModal()
    },
    resetModal() {
      this.reportedBy = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      this.$refs.reportedValidation.validate().then(success => {
        if (success) {
          // Exit when the form isn't valid
          // Push the name to submitted names
          const statusTicket = {
            name: this.reportedBy.Id,
            id: this.idTicket,
          }
          ticketsServices.updateReportedByTicket(statusTicket).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                text: 'The reporter has been set',
                icon: 'InboxIcon',
                variant: 'success',
              },
            })
            this.refreshHandler()
            // Hide the modal manually
            this.$nextTick(() => {
              this.$refs['update-reported-modal'].toggle('#toggle-btn')
            })
          }).catch(error => {
            this.toastErrorHandler(error)
          })
        }
      })

      // Hide the modal manually
    },
  },
}
</script>
