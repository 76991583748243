<template>
  <!-- modal -->
  <b-modal
    id="update-time-modal"
    ref="update-time-modal"
    title="Set Time of Ticket"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    :no-close-on-backdrop="true"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        :state="timeState"
        label="Time"
        label-for="time-input-lbl"
        invalid-feedback="Time is required"
      >
        <b-input-group>
          <b-form-input
            id="time-input"
            v-model="time"
            type="text"
            placeholder="HH:mm:ss"
            autofocus
            required
          />
          <b-input-group-append>
            <b-form-timepicker
              v-model="time"
              button-only
              right
              show-seconds
              locale="en"
              aria-controls="time-input"
            />
          </b-input-group-append>
        </b-input-group>

      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BModal, VBModal, BFormInput, BInputGroup, BFormTimepicker, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ticketsServices from '@core/services/tickets/ticketsService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    BInputGroup,
    BFormTimepicker,
    BInputGroupAppend,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    refreshHandler: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    idTicket: 0,
    time: null,
    timeState: null,
  }),
  created() {
    this.$bus.$on('setParamsUpdateTimeModal', param => {
      const { id } = param
      this.idTicket = id
    })
  },
  methods: {
    resetModal() {
      this.time = ''
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      // Push the name to submitted names
      const statusTicket = {
        time: this.time,
        id: this.idTicket,
      }
      ticketsServices.updateTimeTicket(statusTicket).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            text: 'The time has been set',
            icon: 'InboxIcon',
            variant: 'success',
          },
        })
        this.refreshHandler()
        // Hide the modal manually
        this.$nextTick(() => {
          this.$refs['update-time-modal'].toggle('#toggle-btn')
        })
      }).catch(error => {
        this.toastErrorHandler(error)
      })
      // Hide the modal manually
    },
  },
}
</script>
<style>

.b-form-spinbutton.form-control.d-inline-flex.flex-column.b-time-ampm {
    display: none !important;
}
</style>
