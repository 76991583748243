<template>
  <!-- modal -->
  <b-modal
    id="add-comment-modal"
    ref="add-comment-modal"
    title="Submit Your Comment"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        :state="commentState"
        label="Comment"
        label-for="comment-input"
        invalid-feedback="Comment is required"
      >
        <b-form-textarea
          id="comment-input"
          v-model="comment"
          :state="commentState"
          required
        />
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BModal, VBModal, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ticketsServices from '@core/services/tickets/ticketsService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormGroup,
    BFormTextarea,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    refreshHandler: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    comment: '',
    commentState: null,
    idTicket: 0,
  }),
  created() {
    this.$bus.$on('setParamsAddCommentModal', param => {
      const { id } = param
      this.idTicket = id
    })
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.commentState = valid
      return valid
    },
    resetModal() {
      this.comment = ''
      this.commentState = null
      this.idTicket = 0
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      const comm = {
        text: this.comment,
        id: this.idTicket,
      }
      // Push the name to submitted names
      ticketsServices.commentTicket(comm).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            text: 'Comment has been added succesfully',
            icon: 'InboxIcon',
            variant: 'success',
          },
        })
        this.refreshHandler()
        // Hide the modal manually
        this.$nextTick(() => {
          this.$refs['add-comment-modal'].toggle('#toggle-btn')
        })
      }).catch(error => {
        this.toastErrorHandler(error)
      })
    },
  },
}
</script>
